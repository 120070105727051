<template>
  <div class="footer">
      <div class="footer-box">
        <div class="footer-item">
          <img src="@/assets/img/logo2.png" style="width: 180px"/>
        </div>
        <div class="footer-item1">

          <div class="footer-item__top">
            <div class="footer-item__top-item" @click="$router.push('/')">
              <img src="@/assets/img/foothome.png"/>
              首页
            </div>
            <div class="footer-item__top-item" @click="$router.push('/security')">
              <img src="@/assets/img/footsolution.png"/>
             安全办公
            </div>
            <div class="footer-item__top-item" @click="$router.push('/contact_us')">
              <img src="@/assets/img/footask.png"/>
              招贤纳士
            </div>
            <div class="footer-item__top-item" @click="$router.push('/about')">
              <img src="@/assets/img/footabout.png"/>
              关于我们
            </div>
            <!-- <a class="footer-item__top-item" href='https://console.dsphere.com.cn/#/login'>
              <img src="@/assets/img/footlogin.png"/>
              企业登录
            </a> -->
          </div>

          <div class="footer-item__mil">
            打造高效、快捷、智能、优质的线上线下服务体系
          </div>

          <div class="footer-item__bottom">
            版权所有@2022长沙老铁汽车服务有限公司
            <span><a href="https://beian.miit.gov.cn/" target="_blank">  湘ICP备2021019770号-2</a></span>
          </div>
 <div class="page" style="display: flex; align-items: end;">
          <!-- www.laotiechebanghui.com -->
        <!-- <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010302001474" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/img/beian.png" style="float:left;height:20px;width:20px;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">湘公网安备 43010302001474号</p ></a> -->
         <!-- www.laotiechebanghui.cn备案 -->
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010302001709" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/img/beian.png" style="float:left;height:20px;width:20px;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">湘公网安备 43010302001709号</p ></a>
        </div>
        </div>

        <!-- <div class="footer-item">
          <div class="footer-item--title">
            关于我们
          </div>
          <div class="footer-item--center">
            <span @click="goAbout">
              了解数影星球
            </span>
          </div>
        </div>
        <div class="footer-item">
          <div class="footer-item--title">
            如何购买
          </div>
          <div class="footer-item--center">
            <span @click="goPay">
              在线咨询
            </span>
            <br />
            <span @click="goPay">
              项目咨询
            </span>
          </div>
        </div> -->

        <!-- <div class="footer-item">
          <div class="footer-item--title">
            关注数影星球
          </div>
          <div class="footer-item--center">
            <a-popover placement="bottom">
              <template slot="content">
                <p><img src="er.png" style="width: 100px"/></p>
              </template>
              <img src="wx.png"/>
            </a-popover>
          </div>
        </div> -->
        <div class="footer-item2">
          <img src="@/assets/img/xz.png" style="width: 100px"/>
          <br />
          用户下载
        </div>

        

      </div>
      <!-- <div class="footer-bottom">@2021 数影星球（杭州）科技有限公司 版权所有 备案号：浙ICP备2020043431号-1</div> -->
    </div>
</template>

<script>
export default {
  methods: {
    goPay() {
      this.$router.push('/contact_us')
    },
    goAbout() {
      this.$router.push('/about')
    }
  }
}
</script>

<style lang="less">
 .footer {
  padding: 80px 0 54px;
  background: #F7F7F7;
}
.footer-box {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.footer-bottom {
  text-align: center;
}
.footer-item {
  display: inline-block;
}
.footer-item1 {
  display: inline-block;
  margin: 0 150px;
}
.footer-item2 {
  display: inline-block;
  text-align: center;
  line-height: 34px;
  color: #8990a0;
}
.footer-item--title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 40px;
  color: #333333;
}
.footer-item__top {
  padding: 12px 0;
  color: #011847;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #011847;
  .footer-item__top-item {
    color: #011847;
    margin: 0 12px;
    cursor: pointer;
  }
  img {
    width: 16px;
    vertical-align: sub;
  }
}
.footer-item__mil {
  margin: 16px 0 24px;
  padding-left: 12px;
  color: #011847;
  font-size: 16px;
}
.footer-item__bottom {
  margin: 16px 0 24px;
  padding-left: 12px;
  color: #8990a0;
  font-size: 14px;
  span {
    padding-left: 16px;
  }
}
.footer-item--center {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
  img {
    width: 56px;
    height: 56px;
  }
  span {
    cursor: pointer;
  }
}

</style>
